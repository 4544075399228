import React, {Component} from 'react';
import styles from "./InfoToolbar.module.css";
import QRCode from "qrcode.react";
import {getTranslation} from "../language/Language";
import Utils from "../Utils";


export default class InfoToolbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isExpanded: false
        }
    }

    getQRCode() {
        return <div className={"qrCode"}>
            <QRCode
                value={this.props.observer_url}
                size={180}
                includeMargin={true}
                className={styles.qrCode}
            />
            {getTranslation('scan_qr_code',Utils.language)}
        </div>
    }

    getBaseVersionTexts() {
        return <React.Fragment>
            {getTranslation('tutorial_base', Utils.language)}
            {this.props.deck.categories.map((c,i) => {
                return <React.Fragment key={i}>
                    {getTranslation('tutorial_' + c.category_id, Utils.language)
                }</React.Fragment>
            })}
            {this.getQRCode()}
            {getTranslation('tutorial_feedback', Utils.language)}
        </React.Fragment>
    }

    getAssociationTexts() {
        return <React.Fragment>
            {getTranslation('tutorial_association', Utils.language)}
            {getTranslation('tutorial_evaluation', Utils.language)}
            {getTranslation('tutorial_exercise_1', Utils.language)}
            {getTranslation('tutorial_exercise_2', Utils.language)}
        </React.Fragment>
    }

    getObserverTexts() {
        return <React.Fragment>
            {getTranslation('tutorial_base', Utils.language)}
            {this.props.deck.categories.map((c,i) => {
                return <React.Fragment key={i}>
                    {getTranslation('tutorial_' + c.category_id, Utils.language)}
                </React.Fragment>
            })}
        </React.Fragment>
    }

    getHelpTexts() {

        if (this.props.deck.categories &&
            this.props.deck.categories.length === 1 &&
            this.props.deck.categories[0].category_id === 'association') {
            return this.getAssociationTexts()
        }

        if (this.props.deck.categories &&
            this.props.deck.categories.length > 0 &&
            this.props.deck.categories[0].category_id === 'observer')
            return this.getObserverTexts();


        if (this.props?.deck?.deck_id)
            return this.getBaseVersionTexts();
    }

    renderPopup() {

        if (this.state.isExpanded)
            return <div className={styles.infoPopupContainer}>
                <div className={styles.closeButton} onClick={() => this.setState({isExpanded: false})}>
                    <div className={styles.closeButton_bar1}/>
                    <div className={styles.closeButton_bar2}/>
                </div>
                {this.getHelpTexts()}

            </div>;
        else return null;
    }
    render() {
        //let isSwedish = 0;
        let questionMarkStr = '?';
        let questionMarkClass = styles.helpButton;
        questionMarkStr = getTranslation('how_to_button_text',Utils.language);

        return <React.Fragment>
            <div className={styles.container + ' ' + questionMarkClass} onClick={() => this.setState({isExpanded: !this.state.isExpanded})}><span>{questionMarkStr}</span></div>
            {this.renderPopup()}
        </React.Fragment>
    }
}
import React from "react";

export const english = {
    'not_found_error':
        <React.Fragment>
            <h2>Subscription could not be found</h2>
            <p><a href="mailto:post@speakersdeck.com">Contact us</a> to get help with your subscription.</p>
        </React.Fragment>,
    'expired_error':
        <React.Fragment>
            <h2>Your subscription has expired</h2>
            <p><a href="mailto:post@speakersdeck.com">Contact us</a> if you'd like to extend it.</p>
        </React.Fragment>,
    'unknown_error':
        <React.Fragment>
            <h2>An error occurred</h2>
            <p>$</p>
            <p><a href="mailto:post@speakersdeck.com">Contact us</a> if you'd like to extend it.</p>
        </React.Fragment>,
    'observer_extra': <div>Extra text for observer. How to play for example?</div>,
    'observer_link': 'Observer link:',
    'page_title': 'Speaker\'s deck',
    'about_menu':
        <React.Fragment>
            <h2>Ordrum</h2>
            <p>The Speaker Card Game is produced by Ordrum (<a href={"https://www.speakersdeck.com/"} rel="noreferrer"
                                                               target={"_blank"}>https://www.speakersdeck.com/</a>), a
                company
                that specialises in communication training. We provide training in presentation techniques,
                interaction
                and effective meetings.</p>
            <h2>There are several versions of the game</h2>
            <p>The Speaker Card Game comes in several versions which can be found in Ordrum’s online store (<a
                href={"https://www.ordrum.se/butik"} rel="noreferrer" target={"_blank"}>https://www.speakersdeck.com/butik</a>). Would you
                like
                your own version of the game? Get in touch and we can sort that out for you.</p>

            <h2>Online shop</h2>
            <p>Ordrum’s online shop (<a href={"https://www.ordrum.se/butik"} rel="noreferrer"
                                        target={"_blank"}>https://www.speakersdeck.com/butik</a>) sells materials and books
                for
                communication training.</p>

            <h2>Contact us</h2>
            <p>Questions about our products? Email&nbsp;us&nbsp;at&nbsp;<a href={"mailto:post@speakersdeck.com"}>post@speakersdeck.com</a> or
                phone <a href={"tel:+46317433440"}>+46&nbsp;(0)31&nbsp;743&nbsp;34&nbsp;40</a></p>
        </React.Fragment>,
    'tutorial_base':
        <React.Fragment>
            <h2>How to play</h2>
            <p>The Speaker’s Deck is a set of cards for practising presentation techniques. The cards are divided into
                different categories. Click the cards you want to use. (If you click a second time, the card will be
                removed and you can click again to get a new card).</p>
            <p>It is possible to play the game without time for preparation, but you may also decide to give everyone
                e.g. one minute to prepare.</p>
            <p>Click the circle marked “3 min” to start the timer. The timer will start flashing when there are 30
                seconds left.</p>
        </React.Fragment>
    ,
    'tutorial_association':
        <React.Fragment>
            <h2>How to play</h2>
            <p><i>The Speaker's Deck Game Inspiration</i> is a collection of images that can be used in different ways.</p>
            <p>Click on the card to display an image and click once again to remove the picture.</p>
            <p>If you want to use a timer, you can press the circle labeled "2 minutes" and the timer will count down. When there is half a minute left, the timer will start blinking.</p>
        </React.Fragment>
    ,
    'tutorial_evaluation':
        <React.Fragment>
            <b>Evaluation</b><p>Participants speak from the image they've received about something they have taken from the day.</p>
        </React.Fragment>
    ,
    'tutorial_exercise_1':
        <React.Fragment>
            <b>Storytelling Exercise</b><p>Participants tell about an event in their own lives that they recall when they see the image.</p>
        </React.Fragment>
    ,
    'tutorial_exercise_2':
        <React.Fragment>
            <b>Writing Exercise</b><p>Participants write an imagined story about what is about to happen after the moment caught in the picture.</p>
        </React.Fragment>
    ,
    'tutorial_audience':
        <React.Fragment>
            <p><b>Audience</b> – describe the audience you are giving your talk to.</p>
        </React.Fragment>
    ,
    'tutorial_complication':
        <React.Fragment>
            <p><b>Complication</b> – makes the game even more tricky.</p>
        </React.Fragment>
    ,
    'tutorial_environment':
        <React.Fragment>
            <p><b>Place</b> – tell you the location where you are giving your talk.</p>
        </React.Fragment>
    ,
    'tutorial_role':
        <React.Fragment>
            <p><b>Speaker</b> – give you a role or a perspective that you are speaking from.</p>
        </React.Fragment>
    ,
    'tutorial_observer':
        <React.Fragment>
            <p><b>Observer</b> - gives you instructions on what to give feedback on.</p>
        </React.Fragment>
    ,
    'tutorial_subject':
        <React.Fragment>
            <p><b>Subject</b> – contain the topic of your talk.</p>
        </React.Fragment>
    ,
    'tutorial_feedback':
    <React.Fragment>
        <h2>Getting feedback</h2>
        <p>There are a number of <b>Observer</b> cards that can be used to give the speaker feedback. These each
            give observers something to look out for and report back on. Every person who is going to provide
            feedback gets a unique card via the QR code below. The participants can look at their cards on their
            own phones.</p>
    </React.Fragment>
    ,
    'scan_qr_code': <p>Scan the QR code on your phone</p>,
    'email_body':
        "Welcome to the Speaker Card Game.\n\nYour link to play: $"+
        "\n\n"+
        "Link that can be used by members of your audience to give you feedback: $"+
        "\n\n"+
        "Best wishes\nOrdrum"
    ,
    'email_subject': 'Speaker Card Game',
    'how_to_button_text': 'How to play',
    'ordrum_link': "https://www.speakersdeck.com",
    'talarkortleken_url': "www.speakersdeck.com",
    'swedish': 'Swedish',
    'english': 'English',
}
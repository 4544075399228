import React, {Component} from 'react';
import Deck from "./Deck";
import Utils from "../Utils";
import Message from "../admin/Message";
import Page from "../page/Page";
import InfoPage from "../page/InfoPage";
import Menu from "../page/Menu";
import QRCode from 'qrcode.react';
import CircularTimer from "./CircularTimer";
import {getLanguage, getTranslation} from "../language/Language";

export default class PlayBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            language: getLanguage(),
            deck_subscription_id: null
        }
    }

    loadPlayBoard() {
        Utils.Fetch({
            action: 'play_subscription',
            url_id: this.props.subscriptionID,
        }).then( res => {
            if (res['language'] != null) {
                Utils.language = res['language'];
            }
            this.setState({
                isLoading: false,
                ...res,
            }, () => this.onSubscriptionLoaded());
        }).catch(message => {
            this.setState({
                isError: true,
                isLoading: false,
                message: message.toString(),
            });
        });
    }

    loadObserverBoard() {
        Utils.Fetch({
            action: 'play_observer',
            url_id: this.props.observerURLID,
        }).then( res => {
            if (res['language'] != null) {
                Utils.language = res['language'];
            }

            this.setState({
                isLoading: false,
                ...res,
            }, () => this.onObserverLoaded());

        }).catch(message => {
            this.setState({
                isError: true,
                isLoading: false,
                message: message.toString(),
            });
        });
    }

    saveStatistics(event, eventTarget, eventTargetId) {
        if (eventTargetId === undefined)
            eventTargetId = null;

        Utils.Fetch({
            action:'save_statistics',
            deck_subscription_id: this.state.deck_subscription_id,
            event: event,
            event_target: eventTarget,
            event_target_id: eventTargetId
        }).then(res => {
            // console.log(res);
        }).catch(message => {
            this.setState({
                isError: true,
                message: message.toString(),
            });
        });
    }

    onSubscriptionLoaded() {
        this.saveStatistics('open', 'deck', null);
    }
    onObserverLoaded() {
        this.saveStatistics('open', 'observer', null);
    }
    componentDidMount() {
        if (this.props.subscriptionID)
            this.loadPlayBoard();
        else if (this.props.observerURLID)
            this.loadObserverBoard();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.subscriptionID !== prevProps.subscriptionID)
            this.loadPlayBoard();
        else if (this.props.observerURLID !== prevProps.observerURLID)
            this.loadObserverBoard();
    }

    static renderLoading() {
        return <div>Loading ... </div>;
    }

    renderError() {
        if (this.state.error === 'not_found')
            return <InfoPage>
                    {getTranslation('not_found_error',Utils.language)}
                 </InfoPage>;

        if (this.state.error === 'expired')
            return <InfoPage>
                {getTranslation('expired_error',Utils.language)}
        </InfoPage>;

        return <InfoPage>
            {getTranslation('unknown_error',Utils.language, this.state.error)}
        </InfoPage>;
    }

    getMenuExtraInfo() {
        if (this.props.observerURLID) {
            return getTranslation('observer_extra',Utils.language)
        } else {
            return <React.Fragment>
                {getTranslation('observer_link',Utils.language)} <a href={this.state.observer_url} rel="noreferrer" target={"_blank"}>{this.state.observer_url}</a>
                <br/>
                <QRCode
                    value={this.state.observer_url}
                    size={256}
                    includeMargin={true}
                />
            </React.Fragment>
        }

    }

    render() {
        if (this.state.isLoading)
            return PlayBoard.renderLoading();

        if (this.state.error)
            return this.renderError();

        let categories = this.state.deck?.categories;
        let num = categories?.length ?? 0;
        const InitialTimerMinutes = num > 0 && categories[0].category_id === 'association' ? 2 : 3
        //let categories = this.state.deck.categories.slice(0, num);

        let playBoardNumberClass = 'PlayingBoard';
        if  (this.state.deck)
            playBoardNumberClass = 'PlayingBoard num_'+num;
        return <Page deck={this.state.deck}>
            {this.state.message ? <Message isError={this.state.isError}>
                {this.state.message}
            </Message> : null}
            <Menu deck={this.state.deck} observer_url={this.state.observer_url}>
                <div>{this.getMenuExtraInfo()}</div>
            </Menu>
            {/* <div className="DeckTitle">{deck.name}</div>*/}
            <div className={playBoardNumberClass}>
                {num !== 0 && categories.map(cat =>
                    <Deck key={cat.category_id} {...cat} saveStatisticsCallback={(event, eventTarget, eventTargetId) => this.saveStatistics(event, eventTarget, eventTargetId)}/>
                )}
            </div>

            <CircularTimer minutes={InitialTimerMinutes}/>

            {/*{this.props.subscriptionID ?
                <div style={{position: "absolute", right: "290px", top: "20px"}}><Timer
                    saveStatisticsCallback={(event, eventTarget, eventTargetId) => this.saveStatistics(event, eventTarget, eventTargetId)}/>
                </div>
                :
                null
            }*/}
        </Page>

    }
}
